<template>
    <div>
        <toast />
        <RouterView />
    </div>
</template>

<script lang="ts">
import {
    computed,
    defineComponent,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    watch,
    watchEffect,
} from "vue";
import { useIdle } from "@vueuse/core";
import { RouterView } from "vue-router";
import isNullish from "@/utils/isNullish";
import { HOTJAR_USER_ID } from "@/constants";
import { useToast } from "primevue/usetoast";
import { useFeatureFlag, useGoogleUpload } from "@/composables";
import { useToastStore } from "@/stores/toast";
import { useKriyaStore } from "@/stores/kriya";
import { useAuthStore } from "@/stores/userAuth";
import { useSupplierStore } from "@/stores/supplier";
import { useChatListStore } from "@/stores/chatList";
import { useParagon } from "@/composables/useParagon";
import { useKybStatus } from "@/composables/useKybStatus";
import { useNotificationsStore } from "@/stores/notifications";
import Toast, { type ToastMessageOptions } from "primevue/toast";
import { createHotJarTrackingScript, handleError } from "@/utils";
import { uploadEventBus } from "@/buses/uploadEventBus";
import mixpanel from "mixpanel-browser";

export default defineComponent({
    components: {
        RouterView,
        Toast,
    },
    setup() {
        const authStore = useAuthStore();
        const toastStore = useToastStore();
        const supplierStore = useSupplierStore();
        const kriyaStore = useKriyaStore();
        const notificationsStore = useNotificationsStore();
        const chatListStore = useChatListStore();
        const { initializeFeatureFlags } = useFeatureFlag();
        const { subscribeToParagonEvents, unSubscribeToParagonEvents } =
            useParagon();
        const { fetchKybStatus } = useKybStatus();

        const {
            connect: connectNotifications,
            disconnect: disconnectNotifications,
        } = notificationsStore;

        const toast = useToast();
        const { resumableUploadWorker } = useGoogleUpload();

        /**
         * idle period is defined as 10 minutes
         * if the user remains idle for 10 minutes idle will be toggled to true
         */
        const { idle } = useIdle(10 * 60 * 1000);

        const createCookieScript = () => {
            if (import.meta.env.VITE_CURRENT_ENV === "PRODUCTION") {
                const script = document.createElement("script");
                script.id = "cookieyes";
                script.src =
                    "https://cdn-cookieyes.com/client_data/92ba444ef47267a44a5227ff/script.js";
                document.head.appendChild(script);
            }
        };

        const userUUID = computed(() => authStore.getUserUUID);

        toastStore.$subscribe((mutation, state) => {
            toast.add({ ...state } as ToastMessageOptions);
        });

        /**
         * Appends the hotjar identity script to the head of the document.
         */
        const createHotJarIdentityScript = () => {
            if (import.meta.env.VITE_CURRENT_ENV !== "PRODUCTION") return;

            const script = document.createElement("script");

            script.id = "hotjar-identities";
            script.type = "text/javascript";
            script.text = `
                        var userId = '${HOTJAR_USER_ID}';
                        window.hj('identify', ${HOTJAR_USER_ID}, {
                            'Company name': '${authStore.companyData.name}',
                            'Company type': '${authStore.companyData.company_type}',
                            'Company country': '${authStore.companyData.country}',
                            'User first name': '${authStore.userData.first_name}',
                            'User last name': '${authStore.userData.last_name}',
                            'User email address': '${authStore.userData.email}',
                        });
                        `;

            document.head.appendChild(script);
        };

        watch(
            userUUID,
            () => {
                if (!userUUID.value) return;

                chatListStore.fetchUserChatList();
            },
            { immediate: true }
        );

        watchEffect(() => {
            if (authStore.isSupplier && !isNullish(authStore.companyAddress)) {
                supplierStore.fetchSupplierKybPayoutDetails();
            }

            if (authStore.isBrand) {
                kriyaStore.fetchCompanyDetails();
                fetchKybStatus();
            }

            // Mixpanel initialization
            const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN;
            mixpanel.init(MIXPANEL_TOKEN, {
                debug: import.meta.env.VITE_CURRENT_ENV !== "PRODUCTION", // Enable debug for non-production
                track_pageview: "full-url",
                ignore_dnt: true, // Ignore do-not-track settings
                persistence: "localStorage",
                record_sessions_percent: 100,
                record_mask_text_selector: "",
            });

            // Tracking logic
            if (authStore.auth && authStore.userData) {
                subscribeToParagonEvents();
                initializeFeatureFlags();

                if (import.meta.env.VITE_CURRENT_ENV === "PRODUCTION") {
                    // Initialize Pendo Once the user has been authenticated and we are on production servers
                    // @ts-ignore
                    // eslint-disable-next-line no-undef
                    pendo.initialize({
                        visitor: {
                            id: authStore.userData.uuid,
                            email: authStore.userData.email,
                            firstName: authStore.userData.first_name,
                            lastName: authStore.userData.last_name,
                        },

                        account: {
                            id: authStore.companyData.uuid,
                            accountName: authStore.companyData.name,
                        },
                    });
                }

                // Identify the user in Mixpanel
                mixpanel.identify(authStore.userData.uuid);

                // Set Mixpanel user properties
                mixpanel.people.set({
                    $email: authStore.userData.email,
                    $first_name: authStore.userData.first_name,
                    $last_name: authStore.userData.last_name,
                    company_uuid: authStore.companyData?.uuid || "Unknown",
                    company_name: authStore.companyData?.name || "Unknown",
                    company_type:
                        authStore.companyData?.company_type || "Unknown",
                });

                // Setting masquerade mode super property
                mixpanel.register_once({
                    is_masquerade_mode: authStore.masqueradeMode || false,
                });
            }
        });

        /**
         * if the user remains idle for more than 10 minutes and
         * the user token is not found in local storage then logout the user
         */
        watch(idle, (idleValue) => {
            const userToken = localStorage.getItem("userAuthToken");
            if (idleValue && !userToken) {
                authStore.signout();
            }
        });

        const unwatchAuthStore = watch(authStore, () => {
            if (!authStore.companyData || !authStore.userData || false) return;

            connectNotifications();
            createHotJarTrackingScript();
            createHotJarIdentityScript();
            unwatchAuthStore();
        });

        window.addEventListener("beforeunload", () => {
            disconnectNotifications();
        });

        // on-create life-cycle
        onBeforeMount(async () => {
            createCookieScript();
        });

        /**
         * Sets up event listeners for the Web Worker
         * Handles upload completion and errors
         */
        onMounted(() => {
            resumableUploadWorker.addEventListener(
                "message",
                async (evt: any) => {
                    if (evt.data.error) {
                        handleError(evt.data.error);
                        return;
                    }

                    const payload = {
                        ...evt.data.params,
                        file_name: evt.data.response.name,
                    };

                    await uploadEventBus.executeCallback(
                        evt.data.callbackId,
                        evt.data.modelId,
                        payload
                    );
                }
            );
        });

        onBeforeUnmount(() => {
            disconnectNotifications();
            unSubscribeToParagonEvents();
        });

        return {
            authStore,
        };
    },
});
</script>
