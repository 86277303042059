import Notifications from "@/services/Notifications";
import type { AppAction } from "@/types";
import type { ActionClearTriggerEnum } from "@/types/api/data-contracts";
import { useActionsStore } from "@/stores/actions";
import { useAuthStore } from "@/stores/userAuth";

type RelatedObjectType =
    | "inquiry"
    | "quote"
    | "contract"
    | "order"
    | "ingredient"
    | "product";

/**
 * Action to mark all related actions as clear.
 * @param {Record<inquiry, InquiryResponse>} args
 * @return {boolean} true in order to continue the loop of actions.
 *
 * @todo Add a call to mark related actions as cleared.
 */
export const markRelatedActionsCleared: AppAction<{
    relatedObjectUuid: string | string[];
    relatedObjectType: RelatedObjectType | RelatedObjectType[];
    actionClearTrigger: ActionClearTriggerEnum | ActionClearTriggerEnum[];
}> = async (args) => {
    const authStore = useAuthStore();

    const { relatedObjectUuid, relatedObjectType, actionClearTrigger } = args;

    const notificationsService = Notifications.getInstance();
    const actionsStore = useActionsStore();

    if (Array.isArray(actionClearTrigger)) {
        actionClearTrigger.forEach((clearTrigger, index) => {
            const objectUuid = Array.isArray(relatedObjectUuid)
                ? relatedObjectUuid[index]
                : relatedObjectUuid;

            const objectType = Array.isArray(relatedObjectType)
                ? relatedObjectType[index]
                : relatedObjectType;

            notificationsService.markRelatedActionsAsCleared(
                objectUuid,
                objectType,
                clearTrigger
            );
        });
    } else {
        await notificationsService.markRelatedActionsAsCleared(
            relatedObjectUuid as string,
            relatedObjectType as RelatedObjectType,
            actionClearTrigger
        );
    }

    if (!authStore.masqueradeMode) {
        await actionsStore.initActions();
    }

    return true; // Needed to run the next action!
};
